// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-index-jsx": () => import("./../../../src/pages/Cookie-Policy/index.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-jsx" */),
  "component---src-pages-find-us-index-jsx": () => import("./../../../src/pages/Find-Us/index.jsx" /* webpackChunkName: "component---src-pages-find-us-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/Gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-menus-childrens-menu-index-jsx": () => import("./../../../src/pages/Menus/Childrens-Menu/index.jsx" /* webpackChunkName: "component---src-pages-menus-childrens-menu-index-jsx" */),
  "component---src-pages-menus-index-jsx": () => import("./../../../src/pages/Menus/index.jsx" /* webpackChunkName: "component---src-pages-menus-index-jsx" */),
  "component---src-pages-menus-pizza-index-jsx": () => import("./../../../src/pages/Menus/Pizza/index.jsx" /* webpackChunkName: "component---src-pages-menus-pizza-index-jsx" */),
  "component---src-pages-menus-starters-grill-index-jsx": () => import("./../../../src/pages/Menus/Starters-&-Grill/index.jsx" /* webpackChunkName: "component---src-pages-menus-starters-grill-index-jsx" */),
  "component---src-pages-menus-sunday-lunch-index-jsx": () => import("./../../../src/pages/Menus/Sunday-Lunch/index.jsx" /* webpackChunkName: "component---src-pages-menus-sunday-lunch-index-jsx" */),
  "component---src-pages-menus-traditional-favourites-index-jsx": () => import("./../../../src/pages/Menus/Traditional-Favourites/index.jsx" /* webpackChunkName: "component---src-pages-menus-traditional-favourites-index-jsx" */),
  "component---src-pages-menus-vegan-vegetarian-index-jsx": () => import("./../../../src/pages/Menus/Vegan-&-Vegetarian/index.jsx" /* webpackChunkName: "component---src-pages-menus-vegan-vegetarian-index-jsx" */)
}

